<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        <template v-if="username">
          {{ $t('auth---Welcome, {username}!', { username: username }) }}
        </template>
        <template v-else>{{ $t('auth---Welcome!') }}</template>
      </h2>

      <p class="b-text _note _center">
        {{
          translate(
              'auth---Set your profile. You can use these credentials instead of username.',
          )
        }}
      </p>

      <form class="b-form _code" @submit.prevent="updateUserData">
        <div class="b-row">
          <input
            class="b-input"
            :class="{ _error: !!errors.email }"
            :placeholder="$t('auth---Email')"
            id="email"
            v-model="user.email"
            type="email"
            name="email"
          />
          <div class="b-error" v-if="errors.email" v-html="errors.email"></div>
        </div>

        <div class="b-row">
          <input
            class="b-input"
            :class="{ _error: !!errors.phone }"
            :placeholder="$t('auth---Phone number')"
            id="phone"
            v-model="user.phone"
            type="text"
            name="phone"
          />
          <div class="b-error" v-if="errors.phone" v-html="errors.phone"></div>
        </div>

        <div class="b-row _space_above">
          <button class="b-button _full-width" type="submit">
            {{ $t('auth---Add') }}
          </button>
        </div>
      </form>
    </div>
  </Outer>
</template>

<script>
import Outer from '../components/Outer.vue';
import {mapActions, mapMutations, mapState} from 'vuex';

export default {
  components: {
    Outer,
  },
  data() {
    return {
      email: null,
      phone: null,
    };
  },
  computed: {
    ...mapState({
      step: (state) => state.step,
      username: (state) => state.username,
      errors: (state) => state.errors,
      user: (state) => {
        const { email, phone } = state.user;

        return { email, phone };
      },
    }),
  },
  methods: {
    ...mapActions(['reset', 'setUsername', 'setError', 'setStep']),
    ...mapMutations(['setUser']),
    updateUserData() {
      this.setUser(this.user);
      this.setStep(5);
      this.$router.push({ name: 'auth-set-language' });
    },
  },
};
</script>
